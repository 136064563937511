<template>
    <div id="supplier-progress-report">
        <div class="vx-col w-full mb-6">
            <vx-card title="Filtros">
                <div class="flex pl-2 mb-4">
                    <vs-select
                        v-if="programIdSelected !== null"
                        label="Zonas"
                        v-model="zoneIdSelected"
                        @change="addFilter('zone_id', $event)"
                        class="mr-2">
                        <vs-select-item text="Todas" value="0"></vs-select-item>
                        <vs-select-item
                            v-for="zone in programSelected.zones"
                            :text="zone.name"
                            :key="zone.id"
                            :value="zone.id"></vs-select-item>
                    </vs-select>

                    <vs-select
                        :disabled="zoneIdSelected === 0 && zoneSelected.regions.length < 0"
                        label="Regiones"
                        v-model="regionIdSelected"
                        @change="addFilter('region_id', $event)">
                        <vs-select-item text="Todas" value="0"></vs-select-item>
                        <vs-select-item
                            v-for="region in zoneSelected.regions"
                            :text="region.name"
                            :key="region.id"
                            :value="region.id"></vs-select-item>
                    </vs-select>
                    <div class="w-1/3 mr-3">
                    <label class="ml-4 mb-0 bold" for="">Proveedores</label>
                        <v-select
                            label="text"
                            v-model.lazy="supplierSelected"
                            class="vs-custom w-full ml-3"
                            v-validate="'required'"
                            name="sourceType"
                            placeholder="Seleccione una opción"
                            :clearable="false"
                            :searchable="true"
                            :options="getCountriesList"
                            :reduce="text => text.value"
                            >
                        <div slot="no-options">No hay coincidencias</div> 
                        </v-select>
                    </div>
                    <div class="w-1/6 mr-3">
                        <label class="ml-4 mb-0 bold" for="">Año</label>
                        <v-select
                            label="text"
                            v-model.lazy="currentYear"
                            class="vs-custom w-full ml-3"
                            v-validate="'required'"
                            name="sourceType"
                            placeholder="Seleccione una opción"
                            :clearable="false"
                            :searchable="true"
                            :options="getYearsOptions"
                            :reduce="text => text.value"
                            >
                        <div slot="no-options">No hay coincidencias</div> 
                        </v-select>
                    </div>
                    <vs-button :disabled="supplierSelected == null" @click.stop="getGlobalReport()" type="filled" class="mt-6 ml-4" icon="search"></vs-button>
                </div>
            </vx-card>
        </div>

        <div  v-if="globalReport.length > 0" class="vx-col w-full">
            <vx-card class="mb-base h-auto" title="Resumen de programa.">
                <div class="vx-row">
                    <!-- TABLE ONE -->
                    <div class="vx-col lg:w-1/2 md:w-1/2 sm:w-full w-full">
                        <vx-card class="h-auto" title="Prospectos.">
                            <vs-table stripe :data="globalReport">
                                    <div slot="header">
                                        <!-- TODO -->
                                    </div>
                                    <template slot="thead">
                                        <vs-th class="bold" v-for="(col, idx) in columnsOne" v-bind:key="idx" :sort-key="col.sortable ? col.key : null" >{{col.title}}</vs-th>
                                    </template>
    
                                    <template slot-scope="{data}">
                                        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                                            <template v-if="tr.type == 'special'">
                                                <vs-td>
                                                    <vs-divider class="m-0"><strong>{{tr.status_named}}</strong></vs-divider>
                                                </vs-td>
                                                <vs-td>
                                                    <vs-divider class="m-0"></vs-divider>
                                                </vs-td>
                                                <vs-td >
                                                    <vs-divider class="m-0"></vs-divider>
                                                </vs-td>
                                            </template>
                                            <template v-else>
                                                <vs-td :data="data[indextr].status_named">
                                                    <div class="clickable-img" v-html="data[indextr].status_named" @click.stop="showDetails(data[indextr])"></div>
                                                </vs-td>
                                                <vs-td :data="data[indextr].value">
                                                    <p style="float:right;">
                                                        {{ data[indextr].value}}
                                                    </p>                        
                                                </vs-td>
                                                <vs-td :data="data[indextr].high_range">
                                                    <p style="float:right;">
                                                        {{ data[indextr].percentage.toFixed(0) }} %
                                                    </p> 
                                                </vs-td>
                                            </template>
                                        </vs-tr>
                                        <vs-tr>
                                            <vs-td><div class="bold" style="float:right;">Total</div></vs-td>
                                            <vs-td class="bold" style="float:right;">{{data[0].total}}</vs-td>
                                            <vs-td></vs-td>
                                        </vs-tr>
                                    </template>
                            </vs-table>  
                        </vx-card>
                    </div>
                    <div class="vx-col lg:w-1/2 md:w-1/2 sm:w-full w-full">
                        <vx-card title="Información del proveedor.">
                            <p>Consulta la información de contacto del proveedor del cual se está consultando el reporte de avance.</p>
                            <vx-card no-shadow>
                                <p class="bold">Nombre Comercial: </p><span class="info">{{supplierInfo.supplier.tradename}}</span>
                                <p class="bold">Correo: </p><span class="info">{{supplierInfo.supplier.email}}</span>
                                <p class="bold">Teléfono: </p><span class="info">{{ supplierInfo.supplier.phone.country_calling_code }} {{phoneFormat(supplierInfo.supplier.phone.phone)}}</span>
                                <vs-divider>Agentes</vs-divider>
                                <vs-table stripe :data="supplierInfo !== null ? supplierInfo.supplierAgents : []" noDataText="No se han generado registros">
                                    <template slot="thead">
                                        <vs-th class="bold">Nombre</vs-th>
                                        <vs-th class="bold">Teléfono</vs-th>
                                        <vs-th class="bold">Correo</vs-th>
                                    </template>
                                    <template slot-scope="{data}">
                                        <vs-tr :key="indextr" v-for="(tr, indextr) in data">

                                            <vs-td :data="data[indextr].name">
                                            {{ data[indextr].name }}
                                            </vs-td>

                                            <vs-td v-if="data[indextr].phone" :data="data[indextr].phone">
                                            {{ data[indextr].phone.country_calling_code }} {{ phoneFormat(data[indextr].phone.phone) }}
                                            </vs-td>

                                            <vs-td v-else>
                                            --
                                            </vs-td>

                                            <vs-td :data="data[indextr].email">
                                            {{ data[indextr].email }}
                                            </vs-td>

                                        </vs-tr>
                                    </template>

                                </vs-table>
                            </vx-card>
    
                        </vx-card>
                    </div>
                </div>
            </vx-card>
        </div>

        <div v-else>
            <vs-alert icon-pack="feather" icon="icon-check-square" class="mb-5" color="success">
                <span class="font-regular">
                    Consulta el reporte de avance por proveedor seleccionado a un proveedor participante.
                </span>
            </vs-alert>
        </div>
        <!-- DETALLE -->
        <vs-popup v-if="openPopUp" fullscreen :title="title" :active.sync="openPopUp">
            <potential-applicants 
            :isProgram="true" 
            :currentStatus="currentStatus"
            :isFromPopUp="true"
            :currentZoneId="zoneIdSelected"
            :supplierFilteredId="supplierSelected"
            :currentRegionId="regionIdSelected"></potential-applicants>
        </vs-popup>
    </div>
</template>

<script>
import PotentialApplicants from "@views/supplier/programs/kof/PotentialApplicants.vue";
import DiscardedDashboard from "@views/programs/kof/components/analytics/DiscardedDashboard.vue";
import formatHelper from "@mixins/formatHelper";

const columnsOne = [
    { title: "ESTADO", key: "progress", sortable: false}
    , { title: "CANTIDAD", key: "value", sortable: false}
    , { title: "PORCENTAJE", key: "percentage", sortable: false}
];

export default {
    name: "ProgressReport",
    components: {
        PotentialApplicants,
        DiscardedDashboard
    },
    mixins: [formatHelper],
    data() {
        return {
            globalReport: [],
            fakeData: [],
            columnsOne: columnsOne,
            isMounted: false,
            openPopUp: false,
            title: "Detalle",
            currentStatus: null
            , filters: {}
            , programs: []
            , zoneIdSelected: 0
            , regionIdSelected: 0
            , supplierSelected: null
            , collections: {
               suppliers: []
            }
            , supplierInfo: null
            , currentYear: null
            , openPopUpProject: false
        }
    },
    async created() {
        const {data} = await axios.get(`/api/pgm/me/programs`)
        this.programs = data;
        // this.getGlobalReport();
        this.getCollections();
    },
    computed: {
        programSelected() {
            let program = this.programs.filter(p => p.id === this.programIdSelected)
            return program[0] ?? null;
        },
        zoneSelected() {
            let zone = this.programSelected.zones.filter(z => z.id === this.zoneIdSelected)
            return zone[0] ?? {regions: []};
        },
        getCountriesList() {
			return this.collections.suppliers.map((supplier) => ({ value: supplier.id, text: supplier.select_name }));
		},
        programIdSelected () {
            return this.currentProgramData.id;
        },
        getYearsOptions() {
            const years = [];
            for (let i = new Date().getFullYear(); i >= 2020; i--) {
                years.push({ text: i, value: i });
            }

            return years;
        }
    },
    methods: {
        async getCollections(){
            try {
                let collectionsObjects = ['getPGMSuppliers'];
                let params = "with[]=" + collectionsObjects.join("&with[]=");
                let res = await axios.get(`/api/v1/forms/getFormCollections?${params}`);
                this.collections.suppliers = res.data.getPGMSuppliers;
                this.isMounted = true;
            }
            catch (e) {
                this.warn(e);
            }
        },
        showDetails(item) {
            if(item.type != 'project'){
                this.title = item.title;
                this.currentStatus = item.status;
                this.openPopUp = true;
            }
        },
        handleClose() {
            this.currentStatus = null;
        },
        async getGlobalReport() {
            try {
                this.showLoading(true);
                const programId = this.programIdSelected;
                const res = await axios.get(`/api/kof/get/pgm-reports/${programId}/global-report?region=${this.regionIdSelected}&supplierId=${this.supplierSelected}&year=${this.currentYear}`);
                const supplierInfo = await axios.get(`/api/kof/get/supplier/${this.supplierSelected}/information`);
                this.supplierInfo = supplierInfo.data;
                this.globalReport = res.data;
                this.showLoading(false);
            } catch (error) {
                this.errorNotif(
                    "Error al consultar el reporte de avance.",
                    "Ha ocurrido un error, por favor intente más tarde"
                );
                console.error(error);
                this.showLoading(false);
            }
                this.showLoading(false);
        },
        async getGlobalReportFiltered() {
            try {
                this.showLoading(true);
                const programId = this.programIdSelected;
                const res = await axios.get(`/api/kof/get/pgm-reports/${programId}/global-report?region=${this.regionIdSelected}`);
                this.fakeData = res.data;
                this.showLoading(false);
            } catch (error) {
                this.errorNotif("Ha ocurrido un error en la operación.", error.response.data);
                console.error(error.response);
                this.showLoading(false);
            }
                this.showLoading(false);
        },
        async addFilter(filterName, ev) {
            if (ev.toString() === "0") {
                delete this.filters[filterName];
            } else {
                this.filters[filterName] = ev.toString();
            }

            if(filterName == "zone_id" && ev.toString() === "0"){
                this.zoneIdSelected = 0;
                this.regionIdSelected = 0;
            }
        },
    }
}
</script>

<style>
    #filtered-report td:nth-child(3n+1) {
        cursor: pointer;
    }
</style>