<template>
    <div id="progress-report">
        <div class="vx-row">
            <div class="vx-col w-full mb-6">
                <vx-card class="big-title-card-bg-black h-auto">
                    <h1 class="rg-big-title extrabold rg-title">
                        Reporte de <span>avance</span>.
                    </h1>
                </vx-card>
            </div>

            <div class="vx-col w-full">
                <vx-card>
                    <vs-tabs>
                        <vs-tab label="General" icon-pack="feather" icon="icon-book-open">
                            <div class="vx-row">
                                <!-- TABLE ONE -->
                                <div class="vx-col lg:w-1/2 md:w-1/2 sm:w-full w-full mb-5">
                                    <vx-card>
                                        <h5 class="mb-4">REPORTE GENERAL DEL PROGRAMA</h5>
                                        <vs-table stripe :data="globalReport">
                                                <div slot="header">
                                                    <!-- TODO -->
                                                </div>
                                                <template slot="thead">
                                                    <vs-th class="bold" v-for="(col, idx) in columnsOne" v-bind:key="idx" :sort-key="col.sortable ? col.key : null" >{{col.title}}</vs-th>
                                                </template>

                                                <template slot-scope="{data}">
                                                    <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                                                        <template v-if="tr.type == 'special'">
                                                            <vs-td>
                                                                <vs-divider class="m-0"><strong>{{tr.status_named}}</strong></vs-divider>
                                                            </vs-td>
                                                            <vs-td>
                                                                <vs-divider class="m-0"></vs-divider>
                                                            </vs-td>
                                                            <vs-td >
                                                                <vs-divider class="m-0"></vs-divider>
                                                            </vs-td>
                                                        </template>
                                                        <template  v-else>
                                                            <vs-td :data="data[indextr].status_named">
                                                                <vx-tooltip
                                                                    v-if="data[indextr].need_tooltip"
                                                                    title="Descripción"
                                                                    color="dark"
                                                                    position="left"
                                                                    class="mr-3"
                                                                    style="float:left;cursor:pointer;margin-top:-2px;"
                                                                    :text="data[indextr].tooltip_text">
                                                                    <vs-chip transparent color="dark">i</vs-chip>
                                                                </vx-tooltip>
                                                                <div v-html="data[indextr].status_named">
                                                                </div>
                                                            </vs-td>
                                                            <vs-td :data="data[indextr].value">
                                                                <p style="float:right;">
                                                                    {{ data[indextr].calculated_from != null ? getCalculatedValue(globalReport, data[indextr].calculated_from) : data[indextr].value }}
                                                                </p>                        
                                                            </vs-td>
                                                            <vs-td :data="data[indextr].high_range">
                                                                <p style="float:right;">
                                                                    {{ data[indextr].calculated_from != null ? getCalculatedPercentage(globalReport, data[indextr].calculated_from) : data[indextr].percentage.toFixed(0) }} %
                                                                </p> 
                                                            </vs-td>
                                                        </template>
                                                    </vs-tr>
                                                    <vs-tr>
                                                        <vs-td><div class="bold" style="float:right;">Total</div></vs-td>
                                                        <vs-td class="bold" style="float:right;">{{data[0].total}}</vs-td>
                                                        <vs-td class="bold"><span style="float:right;">100%</span></vs-td>
                                                    </vs-tr>
                                                </template>
                                        </vs-table>  
                                    </vx-card>
                                </div>

                                <div class="vx-col lg:w-1/2 md:w-1/2 sm:w-full w-full mb-5">
                                    <!-- TABLE TWO -->
                                    <div class="vx-col w-full mb-5">
                                        <vx-card>
                                            <div class="flex pl-2 mb-4">
                                                <vs-select
                                                    v-if="programIdSelected !== null"
                                                    label="Zonas"
                                                    v-model="zoneIdSelected"
                                                    @change="addFilter('zone_id', $event)"
                                                    class="mr-2">
                                                    <vs-select-item text="Todas" value="0"></vs-select-item>
                                                    <vs-select-item
                                                        v-for="zone in programSelected.zones"
                                                        :text="zone.name"
                                                        :key="zone.id"
                                                        :value="zone.id"></vs-select-item>
                                                </vs-select>
                                                <vs-select
                                                    :disabled="zoneIdSelected == 0 && zoneSelected.regions.length < 1"
                                                    label="Regiones"
                                                    v-model="regionIdSelected"
                                                    @change="addFilter('region_id', $event)"
                                                    class="mr-2">
                                                    <vs-select-item text="Todas" value="0"></vs-select-item>
                                                    <vs-select-item
                                                        v-for="region in zoneSelected.regions"
                                                        :text="region.name"
                                                        :key="region.id"
                                                        :value="region.id"></vs-select-item>
                                                </vs-select>
                                                <div class="w-1/4 mr-3">
                                                    <label class="ml-4 mb-0 bold" for="">Año</label>
                                                    <v-select
                                                        label="text"
                                                        v-model.lazy="currentYear"
                                                        class="vs-custom w-full ml-3"
                                                        v-validate="'required'"
                                                        name="sourceType"
                                                        placeholder="Seleccione una opción"
                                                        :clearable="false"
                                                        :searchable="true"
                                                        :options="getYearsOptions"
                                                        :reduce="text => text.value"
                                                        >
                                                    <div slot="no-options">No hay coincidencias</div> 
                                                    </v-select>
                                                </div>
                                                <vs-button @click.stop="getGlobalReportFiltered()" type="filled" class="mt-6 ml-4" icon="search"></vs-button>
                                            </div>
                                            <vs-table id="filtered-report" stripe :data="fakeData">
                                                <div slot="header">
                                                    <!-- <p>{{filtersSelected !== null ? filtersSelected.label : "--"}}</p> -->
                                                </div>
                                                <template slot="thead">
                                                    <vs-th class="bold" v-for="(col, idx) in columnsOne" v-bind:key="idx" :sort-key="col.sortable ? col.key : null" >{{col.title}}</vs-th>
                                                </template>

                                                <template slot-scope="{data}">
                                                    <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                                                        <template v-if="tr.type == 'special'">
                                                            <vs-td>
                                                                <vs-divider class="m-0"><strong>{{tr.status_named}}</strong></vs-divider>
                                                            </vs-td>
                                                            <vs-td>
                                                                <vs-divider class="m-0"></vs-divider>
                                                            </vs-td>
                                                            <vs-td >
                                                                <vs-divider class="m-0"></vs-divider>
                                                            </vs-td>
                                                        </template>
                                                        <template  v-else>
                                                            <vs-td :data="data[indextr].status_named">
                                                                <div v-html="data[indextr].status_named" @click="showDetails(data[indextr])">
                                                                    
                                                                </div>
                                                            </vs-td>
                                                            <vs-td :data="data[indextr].value">
                                                                <p style="float:right;">
                                                                    {{ data[indextr].calculated_from != null ? getCalculatedValue(fakeData, data[indextr].calculated_from) : data[indextr].value }}
                                                                </p>                        
                                                            </vs-td>
                                                            <!-- <vs-td v-else :data="data[indextr].value">
                                                                <p style="float:right;">
                                                                    {{ calculateCanceneledStats(fakeData)}}
                                                                </p>                        
                                                            </vs-td> -->
                                                            <vs-td :data="data[indextr].high_range">
                                                                 <p style="float:right;">
                                                                    {{ data[indextr].calculated_from != null ? getCalculatedPercentage(fakeData, data[indextr].calculated_from) : data[indextr].percentage.toFixed(0) }} %
                                                                </p> 
                                                            </vs-td>
                                                        </template>
                                                    </vs-tr>
                                                    <vs-tr>
                                                        <vs-td><div class="bold" style="float:right;">Total</div></vs-td>
                                                        <vs-td class="bold" style="float:right;">{{data[0].total}}</vs-td>
                                                        <vs-td></vs-td>
                                                    </vs-tr>
                                                </template>
                                            </vs-table>
                                        </vx-card>
                                    </div>
                                </div>
                                <p class="bold ml-4">Nota: Información en tiempo real.</p> 
                            </div>
                        </vs-tab>
                        <vs-tab label="Descartados" icon-pack="feather" icon="icon-user-x">
                            <discarded-dashboard></discarded-dashboard>
                        </vs-tab>
                        <vs-tab label="Por proveedor" icon-pack="feather" icon="icon-sun">
                            <supplier-progress-report-widget></supplier-progress-report-widget>
                        </vs-tab>
                        <!-- FUERZA DE VENTA -->
                        <vs-tab label="Fuerza de venta" icon-pack="feather" icon="icon-users">
                            <metabase-dashboard
                                :dashboard-id="64"
                                params="theme=transparent&bordered=false&titled=false" />
                        </vs-tab>
                        <!-- FUERZA DE VENTA -->
                    </vs-tabs>
                </vx-card>
            </div>
        </div>

        <!-- DETALLE -->
        <vs-popup v-if="openPopUp" fullscreen :title="title" :active.sync="openPopUp">
            <potential-applicants 
            :isProgram="true" 
            :currentStatus="currentStatus"
            :isFromPopUp="true"
            :currentZoneId="zoneIdSelected"
            :currentRegionId="regionIdSelected"></potential-applicants>
        </vs-popup>

        <!-- DETALLE -->
        <vs-popup id="projects-in-process" v-if="openPopUpProject" fullscreen :title="title" :active.sync="openPopUpProject">
            <projects-in-process
                :currentStatus="currentStatus"
                :isFromPopUp="true"
                :currentZoneId="zoneIdSelected"
                :currentRegionId="regionIdSelected" />
        </vs-popup>
    </div>
</template>

<script>
import PotentialApplicants from "@views/supplier/programs/kof/PotentialApplicants.vue";
import DiscardedDashboard from "@views/programs/kof/components/analytics/DiscardedDashboard.vue";
import ProjectsInProcess from "./ProjectsInProcess.vue";
import SupplierProgressReportWidget from '../../../../src/components/supplier/widgets/SupplierProgressReportWidget.vue';
import MetabaseDashboard from "@components/metabase/MetabaseDashboard.vue";

const columnsOne = [
    { title: "ESTADO", key: "progress", sortable: false}
    , { title: "CANTIDAD", key: "value", sortable: false}
    , { title: "PORCENTAJE", key: "percentage", sortable: false}
];

export default {
    name: "ProgressReport",
    components: {
        PotentialApplicants,
        DiscardedDashboard,
        ProjectsInProcess
        , SupplierProgressReportWidget
        , MetabaseDashboard
    },
    data() {
        return {
            globalReport: [],
            fakeData: [],
            columnsOne: columnsOne,
            openPopUp: false,
            openPopUpProject: false,
            title: "Detalle",
            currentStatus: null
            , filters: {}
            , programs: []
            , zoneIdSelected: 0
            , regionIdSelected: 0
            , currentYear: null
        }
    },
    async created() {
        const {data} = await axios.get(`/api/pgm/me/programs?program_id=${this.currentProgramData.id}`)
        this.programs = data;
        this.getGlobalReport();
    },
    computed: {
        programSelected() {
            let program = this.programs.filter(p => p.id === this.programIdSelected)
            return program[0] ?? null;
        },
        zoneSelected() {
            let zone = this.programSelected.zones.filter(z => z.id === this.zoneIdSelected)
            return zone[0] ?? {regions: []};
        },
        programIdSelected () {
            return this.currentProgramData.id;
        },
        getYearsOptions() {
            const years = [];
            for (let i = new Date().getFullYear(); i >= 2020; i--) {
                years.push({ text: i, value: i });
            }

            return years;
        }
    },
    methods: {
        showDetails(item) {
            if(item.type == 'potential_applicants'){
                this.openPopUp = true;
            } else {
                this.openPopUpProject = true;
            }
                this.title = item.title;
                this.currentStatus = item.status;
        },
        handleClose() {
            this.currentStatus = null;
        },
        async getGlobalReport() {
            try {
                this.showLoading(true);
                const programId = this.currentProgramData.id;
                const res = await axios.get(`/api/kof/get/pgm-reports/${programId}/global-report?region=0`);
                this.globalReport = res.data;
                this.showLoading(false);
            } catch (error) {
                this.errorNotif(
                    "Error al consultar el reporte de avance.",
                    "Ha ocurrido un error, por favor intente más tarde"
                );
                console.error(error);
                this.showLoading(false);
            }
                this.showLoading(false);
        },
        async getGlobalReportFiltered() {
            try {
                this.showLoading(true);
                const programId = this.currentProgramData.id;
                let zoneSelected = (this.zoneIdSelected != 0 && this.zoneIdSelected != "0" && this.zoneIdSelected != null) ? this.zoneIdSelected : null;
                const res = await axios.get(`/api/kof/get/pgm-reports/${programId}/global-report?region=${this.regionIdSelected}&zone=${zoneSelected}&year=${this.currentYear}`);
                this.fakeData = res.data;
                this.showLoading(false);
            } catch (error) {
                this.errorNotif("Ha ocurrido un error en la operación.", error.response.data);
                console.error(error.response);
                this.showLoading(false);
            }
                this.showLoading(false);
        },
        async addFilter(filterName, ev) {
            if (ev.toString() === "0") {
                delete this.filters[filterName];
            } else {
                this.filters[filterName] = ev.toString();
            }

            if(filterName == "zone_id" && ev.toString() === "0"){
                this.zoneIdSelected = 0;
                this.regionIdSelected = 0;
            }
        },
        calculateCanceneledStats(data) {
            let canceledTotal = 0;
            data.forEach(element => {
                if(element.status_named == "-> Solicitudes rechazadas" 
                    || element.status_named == "-> Proyectos archivados"
                    || element.status_named == "-> Proyectos reembolsados") {
                        canceledTotal += element.value;

                }
            });

            return canceledTotal;
        },
        getCalculatedValue(data, calculatedFrom) {
            let totalStats = 0;
            data.forEach(element => {
                if(calculatedFrom.includes(element.alias_prop)) {
                    totalStats += element.value;
                }
            });

            return totalStats;
        },
        getCalculatedPercentage(data, calculatedFrom) {
            let totalStats = 0;
            let grandTotal = 0;
            data.forEach(element => {
                grandTotal = element.total;
                if(calculatedFrom.includes(element.alias_prop)) {
                    totalStats += element.value;
                }
            });

            return isNaN((totalStats * 100 / grandTotal)) ? "0" : (totalStats * 100 / grandTotal).toFixed(0);
        },
    }
}
</script>

<style>
    #filtered-report td:nth-child(3n+1) {
        cursor: pointer;
    }

    #projects-in-process {
        z-index: 52999 !important;
    }
</style>